import { Icon } from './Icon';
import { JSXOutput, PropsOf, Slot, component$ } from '@builder.io/qwik';
import { VariantProps, tv } from 'tailwind-variants';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { twMerge } from 'tailwind-merge';

export interface ButtonProps
  extends VariantProps<typeof buttonVariants>,
    PropsOf<'button'> {
  class?: string;
  loadingText?: string;
  buttonText?: string | JSXOutput;
}

export const Button = component$(
  ({
    class: className,
    icon,
    variant,
    size,
    loading,
    loadingText,
    disabled,
    buttonText,
    ...props
  }: ButtonProps) => (
    <button
      {...props}
      class={twMerge(buttonVariants({ variant, size, icon }), className)}
      disabled={loading || disabled}
    >
      {loading ? (
        <>
          <Icon class="mr-2 animate-spin" icon={faSpinnerThird} />
          {loadingText && <span>{loadingText}</span>}
        </>
      ) : buttonText ? (
        <>{buttonText}</>
      ) : (
        <Slot />
      )}
    </button>
  )
);

const buttonVariants = tv({
  base: 'ring-offset-background focus-visible:ring-ring inline-flex items-center justify-center whitespace-nowrap rounded-lg text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 [&>svg+span]:ml-2 [&>span+svg]:ml-2 focus:ring-4 focus:ring-primary-300',
  variants: {
    variant: {
      default:
        'bg-white shadow-xs border border-gray-200 text-slate-800 hover:bg-secondary-50 hover:border-secondary-300 hover:text-secondary-700 focus:ring-4 focus:ring-secondary-200 focus:ring-offset-0 active:ring-4 active:ring-secondary-200 active:ring-offset-0 focus:border-secondary-300 active:border-secondary-300',
      outline: 'shadow-xs border border-gray-300 bg-white hover:bg-slate-50',
      ghost: 'bg-transparent text-gray-600 hover:bg-gray-50',
      brand:
        'bg-primary-600 hover:bg-primary-500 text-white focus:ring-4 focus:ring-primary-300 focus:ring-offset-0 active:ring-4 active:ring-primary-300 active:ring-offset-0',
      danger: 'bg-red-600 hover:bg-red-600/90 text-white',
      secondary: 'bg-secondary-600 hover:bg-secondary-600/90 text-white',
      link: 'text-primary-600 !h-auto !p-0 underline-offset-4 hover:underline'
    },
    size: {
      default: 'h-9 px-4 py-2',
      // eslint-disable-next-line @kratos/no-nonsense-tw
      xs: 'h-6 px-2 text-xs',
      sm: 'h-8 px-3 text-xs',
      lg: 'h-12 px-8 text-base',
      xl: 'h-14 px-8 text-base'
    },
    loading: {
      true: ''
    },
    icon: {
      true: '!p-0 items-center justify-center'
    }
  },
  compoundVariants: [
    {
      icon: true,
      size: 'default',
      class: 'size-9'
    },
    {
      icon: true,
      size: 'sm',
      class: 'size-8'
    },
    {
      icon: true,
      size: 'lg',
      class: 'size-12'
    },
    {
      icon: true,
      size: 'xl',
      class: 'size-14'
    }
  ],
  defaultVariants: {
    variant: 'brand',
    size: 'default'
  }
});
